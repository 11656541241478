











































































































import { Component, Vue } from 'vue-property-decorator'
import { deleteNew, getNews } from '@/api/news'
import {

  createFilter,

  getSortItems

} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import {
  debounce,
  parseTime,
  confirmDialog,
  successMsg,
  errorMsg
} from '@/utils'

import { AppModule, DeviceType } from '@/store/modules/app'

@Component({
  name: 'NewsList',
  components: {
    Pagination,
    ListToolbar
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private search = ''
  private selected = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)

  private parseTime = parseTime

  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteNew({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/news/edit/' + id)
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      const { data } = await getNews(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
