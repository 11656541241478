var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",staticStyle:{"background-color":"#efeff1"}},[_c('list-toolbar',{attrs:{"with-pagination":true,"list-query":_vm.listQuery,"total":_vm.total,"has-selected":_vm.selected.length,"title":_vm.$t('route.newsList')},on:{"value-changed":_vm.onSearchChanged,"pagination":_vm.getList}}),_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table",attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":""},on:{"sort-change":_vm.handleSortChange,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('locale.title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.locale))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('form.title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('form.description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.description))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"dateCreated","label":_vm.$t('table.dateCreated'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.news_date)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.actions'),"width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['EDIT_NEWS', 'READ_NEWS']),expression:"['EDIT_NEWS', 'READ_NEWS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.redirectToEdit(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.edit'))+" ")])],1),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['DELETE_NEWS']),expression:"['DELETE_NEWS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.deleteItem(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.delete'))+" ")])],1)]}}])})],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }